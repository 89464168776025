/* Rooms */
.room-wrap {
  padding: 2rem 1.5rem;
}

/* Rooms - dropdown */
.cuzDrop1 .ant-dropdown-menu {
  min-width: 8.125rem;
}
.cuzDrop1 .ant-dropdown-menu-title-content {
  font-family: "pretendard-600";
  font-size: 0.875rem;
}
.cuzDrop1 .ant-dropdown-menu > li:first-child {
  pointer-events: none;
  cursor: default;
  border-bottom: 1px solid #eee;
  border-radius: 0;
  margin-bottom: 0.25rem;
}
.cuzDrop1 .ant-dropdown-menu > li:first-child > span > div {
  color: #222;
  font-family: "pretendard-800";
  font-size: 1rem;
  text-align: center;
}

.cuzDrop2 .ant-dropdown-menu {
  min-width: 6.125rem;
}
.cuzDrop2 .ant-dropdown-menu-title-content {
  font-family: "pretendard-600";
  font-size: 0.875rem;
}

/* Rooms - Card */
.card-wrap {
  width: 100%;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 0.5rem;
}

.card-wrap .state-bar {
  height: 0.625rem;
  background: #eee;
  border-radius: 1rem 1rem 0 0;
  margin-bottom: 0.625rem;
}
.card-wrap .state-bar.red {
  background: red;
}
.card-wrap .state-bar.green {
  background: green;
}
.card-wrap .state-bar.orange {
  background: orange;
}
.card-wrap .state-bar.yellow {
  background: yellow;
}
.card-wrap .state-bar.purple {
  background: purple;
}

.room-card-tit {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 0.5rem;
}
.room-card-tit .ellipsis {
  max-width: 3.125rem;
}

.card-wrap .state-icons {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  min-height: 6.875rem;
  gap: 0.5rem;
}
.card-wrap .state-icons .state-btn {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.card-wrap .state-icons .state-btn .ant-tag {
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.card-wrap .state-icons .state-btn .ant-tag > img {
  filter: brightness(0) invert(1);
}

.card-wrap .state-icons .img-wrap {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-wrap .state-icons .img-wrap img {
  width: 80%;
  max-width: 6.25rem;
  height: auto;
  object-fit: contain;
}
.card-wrap .state-icons .img-wrap span {
  color: #fff;
  font-family: "pretendard-500";
  font-size: 0.75rem;
  text-align: center;
  width: fit-content;
  padding: 0.125rem 0.5rem;
  border-radius: 0.25rem;
  background: rgba(0, 0, 0, 0.5);
  margin-top: 0.5rem;
  white-space: nowrap;
}
.card-wrap .state-icons .img-wrap span.red {
  background: rgba(255, 0, 0, 0.8);
}
.card-wrap .state-icons .img-wrap span.green {
  background: rgba(0, 128, 0, 0.8);
}

.card-wrap .footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.375rem 0.5rem 0.375rem 1rem;
}
.card-wrap .footer .result {
  font-family: "pretendard-500";
  font-size: 0.875rem;
  text-align: left;
}
.card-wrap .footer .more {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efefef;
  border-radius: 50%;
  padding: 0.25rem;
  cursor: pointer;
}

/* Room - modal > tab */
.label {
  padding-right: 1rem;
  padding-bottom: 0.125rem;
  white-space: nowrap;
}
.sec-2 {
  justify-content: space-between;
}
.btn-group {
  text-align: right;
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
}
.payment-wrap {
  /* max-height: 11.188rem; */
  max-height: 179px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem 0;
  border-radius: 0.5rem;
  background: #f1f1f1;
}
.payment-wrap
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 11.375rem;
}
.hr-b-8 {
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
}
.payment-wrap .hr-b-8:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
@media screen and (max-width: 376px) {
  .payment-wrap
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    width: 9.125rem;
  }
}

/* mobile-bg */
.mobile-bg {
  width: 100%;
  height: 39.188rem;
  background: url("./images/mobile.png") no-repeat;
  background-size: 59.375rem 50rem;
  background-position: 50% 50%;
  box-sizing: border-box;
  position: relative;
  opacity: 0.6;
}
.cont-area {
  box-sizing: border-box;
  position: relative;
  top: -35.625rem;
  width: 19rem;
  height: 33.75rem;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.cont-area .ant-input-textarea-allow-clear > .ant-input {
  padding-inline-end: 2.5rem;
}
.cont-area .ant-input-textarea-show-count .ant-input-data-count {
  bottom: -1.875rem;
}
.cont-area
  .ant-input-textarea-affix-wrapper.ant-input-affix-wrapper
  > textarea.ant-input {
  font-size: 1.25rem;
  color: #666;
}
@media screen and (max-width: 870px) {
  .mobile-bg {
    height: 47rem;
    background-size: 57.375rem 59rem;
  }
  .cont-area {
    top: -42.625rem;
    height: 36.75rem;
  }
  .cont-area
    .ant-input-textarea-affix-wrapper.ant-input-affix-wrapper
    > textarea.ant-input {
    font-size: 1.125rem !important;
  }
}
@media screen and (max-width: 390px) {
  .mobile-bg {
    height: 44rem;
    background-size: 53.375rem 55rem;
  }
  .cont-area {
    top: -39.625rem;
    width: 17rem;
  }
  .cont-area .ant-input-number .ant-input-number-input {
    padding: 0.25rem 0.375rem;
    width: 47px !important;
  }
}

/* dashbord */
.dashbord-top-card {
  width: 100%;
  min-width: 14.125rem;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  background-image: url("../src/images/chart-bg.png");
  background-repeat: no-repeat;
  background-size: 50% 120%;
  background-position: 120% -20%;
  opacity: 1;
  height: 100%;
  position: relative;
  z-index: 1;
}
.dashbord-top-card .mask {
  position: absolute;
  z-index: 2;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 9.813rem;
}
.dashbord-top-card .mask2 {
  position: relative;
  z-index: 3;
  background: rgba(213, 193, 251, 0.2);
  padding: 0.5rem 1rem 1rem 0.5rem;
}
.dashbord-top-card p {
  color: #490dbb;
  /* margin-left: -2.5rem; */
  margin-left: 1.5rem;
  text-align: left;
}
.dashbord-top-card:hover .mask,
.dashbord-top-card:active .mask,
.dashbord-top-card:focus .mask {
  opacity: 0.5;
  transition: all 0.4s ease-in-out;
}

.dashbord-top-card.ant-card .ant-card-body,
.dashbord-top-card .ant-card-head {
  padding: 0;
}
.anim-icon svg {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.125rem;
  margin-top: 0.313rem;
}
@media screen and (max-width: 1240px) {
  .dashbord .grid-col-4 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media screen and (max-width: 870px) {
  .dashbord-top-card .font-24-600 {
    font-size: 1.3rem !important;
  }
  .dashbord-top-card p {
    font-size: 1.8rem !important;
  }
}
@media screen and (max-width: 430px) {
  .dashbord .grid-col-4 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

/* dashbord-top-card > anim-btn */
.anim-btn {
  width: 3rem;
  height: 3rem;
  color: #fff;
  border-radius: 0.375rem;
  padding: 0;
  margin: 0;
  font-family: "pretendard-600";
  font-size: 1.125rem;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
}
.btn-color {
  width: 3rem;
  border: none;
  background: #9d50bb;
  background: -webkit-linear-gradient(to right, #9d50bb, #7c3ef2);
  background: linear-gradient(to right, #9d50bb, #7c3ef2);
  transition: all 0.4s ease-in-out;
}
.btn-color:hover {
  color: #5a10e7;
  background: transparent;
  box-shadow: none;
}

.btn-color:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 2px;
  width: 0;
  background: #9d50bb;
  background: -webkit-linear-gradient(to right, #9d50bb, #7c3ef2);
  background: linear-gradient(to right, #9d50bb, #7c3ef2);
  box-shadow: -1px -1px 5px 0px #fff, 7px 7px 20px 0px #0003,
    4px 4px 5px 0px #0002;
  transition: 400ms ease all;
}
.btn-color:after {
  right: inherit;
  top: inherit;
  left: 0;
  bottom: 0;
}
.btn-color:hover:after {
  width: 100%;
  transition: 800ms ease all;
}

/* chart */
.nivo-tooltip {
  background: rgba(248, 240, 255, 0.95);
  font-family: "pretendard-600";
  font-size: 1rem;
  width: fit-content;
  min-width: 6rem;
  border: 1px solid #e3d5fd;
  border-radius: 0.25rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 0.5rem 1rem 0.5rem 0.5rem;
}
.nivo-tooltip > .tit {
  color: red;
  font-family: "pretendard-700";
  font-size: 1.125rem;
  display: flex;
  align-items: center;
}
.nivo-tooltip > .tit > span {
  font-size: 0.875rem;
}
.nivo-tooltip strong {
  font-family: "pretendard-900";
}

.chart-wrap {
  width: 100%;
}
.chart-wrap.line-wrap {
  overflow: hidden;
  width: 100%;
  height: 22rem;
  /* border: 1px solid red; */
  box-sizing: border-box;
  scrollbar-color: #dbdbdf #f3f3f3;
  scrollbar-width: thin;
  scroll-behavior: smooth;
}
@media screen and (max-width: 1200px) {
  .dashbord .grid-col-3 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .dashbord .grid-span-2 {
    grid-column: span 1;
  }
}
@media screen and (max-width: 400px) {
  .chart-wrap {
    height: 19rem !important;
  }
}

/* 예약 등록 모달 */
.resRegModal {
}
.resRegModal .flex-row {
  /* border: 1px solid red; */
}
@media screen and (max-width: 600px) {
  .resRegModal.grid-col-2 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
  .resRegModal.grid-col-2 .grid-span-2 {
    grid-column: span 1 !important;
  }
}
